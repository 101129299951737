<template>
  <div class="home">
    <video id="local" controls autoplay playsinline muted></video>
    <video id="remote" controls autoplay playsinline></video>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data: {
    pc: null,
  },
  components: {},
  methods: {
    async init(params) {
      this.pc = new RTCPeerConnection();
      // 获取本地端视频标签
      const localVideo = document.getElementById('local');
      // 获取远程端视频标签
      const remoteVideo = document.getElementById('remote');

      // 采集本地媒体流
      const localStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
      console.log(localStream);
      // 设置本地视频流
      localVideo.srcObject = localStream;

      // 添加本地媒体流的轨道都添加到 RTCPeerConnection 中
      localStream.getTracks().forEach((track) => {
        this.pc.addTrack(track, localStream);
      });

      this.pc.ontrack = (event) => {
        remoteVideo.srcObject = event.streams[0];
      };
    },
  },

  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.home {
  width: 100vw;
  height: 100vh;
}
</style>
